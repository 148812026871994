<template lang="pug">
    .container.w-96
        add-files
</template>

<script>
//Components
import AddFiles from '@/components/views/files/add-files';

export default {
    name: "AddFilesPage",
    components: {
        'add-files': AddFiles,
    },
}
</script>