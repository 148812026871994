<template lang="pug">
    .container
        text-input(:label="FORMS_LABELS.name" @value="title = $event" :value="title")
        text-area.mt-2(:label="FORMS_LABELS.description" @value="description = $event" :value="description")
        text-input.mt-2(:label="FORMS_LABELS.refer" @value="refer = $event" :value="refer")
        choose-file.mt-5(@value="files = $event" :value="Array.from(files)")
        regular-button.w-32.mt-6(:value="BUTTONS_VALUES.upload" @click="onFile")
</template>

<script>
//Components
import TextInput from '@/components/common/forms/text-input';
import TextArea from '@/components/common/forms/text-area';
import ChooseFile from '@/components/common/forms/choose-file';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
import { FilesNameSpace, FilesActionTypes, FilesGetterTypes } from '@/store/files/types';
//Config
import { FORMS_LABELS, BUTTONS_VALUES } from '@/configs/names.js'
//Helpers
import { getUserAuthData } from '@/helpers/authorization';

export default {
    name: "AddFilesComponent",
    components: {
        'text-input': TextInput,
        'text-area': TextArea,
        'choose-file': ChooseFile,
        'regular-button': RegularButton,
    },
    data() {
        return {
            title: '',
            description: '',
            refer: null,
            files: [],
        }
    },
    computed: {
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        loadedFiles() {
            return this.$store.getters[`${FilesNameSpace}/${FilesGetterTypes.GetLoadedFiles}`]
        },
    },
    methods: {
        getUserAuthData() {
            return getUserAuthData()
        },
        async onFile() {
            try {
                const form_data = new FormData()
                form_data.append('files', this.files[0])
                const response = await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFile}`, { files: form_data })
                if (!response.data.error_message) {
                    const { title, description, refer } = this.$data
                    const { _id, name, type, size, path } = this.loadedFiles
                    await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFileDetails}`, { 
                            details: {
                                title,
                                description,
                                refer,
                                file_id: _id,
                                user_id: this.getUserAuthData().id,
                                name,
                                type,
                                size,
                                path,
                            } 
                        }
                    )
        
                    this.clearForm()
                }
            } catch (error) {
                console.log('error', error.message)
            }
        },
        clearForm() {
            this.title = ''
            this.description = ''
            this.files = []
        }
    }
}
</script>